import Axios from '../services/Axios'

const AccessoryApis = {
  getListAccessoris(params) {
    return Axios.getInstance().get('/api/v1/accessories', { params })
  },
  createAccessoris(data) {
    return Axios.getInstance().post('/api/v1/accessories', data)
  },
  getAccessorisById(id) {
    return Axios.getInstance().get(`/api/v1/accessories/${id}`)
  },
  editAccessoris(id, data) {
    return Axios.getInstance().put(`/api/v1/accessories/${id}`, data)
  },
  getSerialById(id, params) {
    return Axios.getInstance().get(`/api/v1/accessories/${id}/serials`, { params })
  },
  getImportTicket(params) {
    return Axios.getInstance().get('/api/v1/accessories/import-accessory/tickets', { params })
  },
  getListAuditor() {
    return Axios.getInstance().get('/api/v1/accessories/import-accessory/auditors')
  },
  uploadTicket(data) {
    return Axios.getInstance().post('/api/v1/accessories/import-accessory', data)
  },
  listAccessorylApi(params) {
    return Axios.getInstance().get('/api/v1/accessories/import-accessory/auditors', { params })
  },
  listTicketApi(id) {
    return Axios.getInstance().get(`/api/v1/accessories/import-accessory/tickets/${id}`)
  },
  listSerialOfTicketApi(id, params) {
    return Axios.getInstance().get(`/api/v1/accessories/import-accessory/tickets/${id}/serials`, { params })
  },
  uploadFile(formData) {
    return Axios.getInstance().post('/api/v1/upload-excel-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteSerial(ids) {
    return Axios.getInstance().delete('/api/v1/serials', {
      data: { ids },
    })
  },
  deleteAccessory(ids) {
    return Axios.getInstance().delete('/api/v1/accessories', {
      data: { ids },
    })
  },
  getExportTicket(params) {
    return Axios.getInstance().get('/api/v1/accessories/export-accessory/tickets', { params })
  },
  createExportTicket(data) {
    return Axios.getInstance().post('/api/v1/accessories/export-accessory/ticket', data)
  },
  detailExportTicket(id) {
    return Axios.getInstance().get(`/api/v1/accessories/export-accessory/tickets/${id}?is_with_accessories=true`)
  },
  approveExportTicket(id) {
    return Axios.getInstance().put(`/api/v1/accessories/export-accessory/ticket/${id}/approve`)
  },
  rejectExportTicket(id, data) {
    return Axios.getInstance().put(`/api/v1/accessories/export-accessory/ticket/${id}/reject`, data)
  },
  cancelExportTicket(id, data) {
    return Axios.getInstance().put(`/api/v1/accessories/export-accessory/ticket/${id}/cancel`, data)
  },
}

export default AccessoryApis
