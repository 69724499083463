/* eslint-disable jsx-a11y/no-static-element-interactions */
const FilterItem = (props) => {
  const {
    filterKey, filterName, isActive, handleFilterKey, hasImage, imgWidth, imgHeight, imgUrl, avaiableCouter,
  } = props
  return (
    <div className={`filter-item${hasImage ? '-image' : ''} ${isActive ? `filter-item${hasImage ? '-active-image' : '-active'}` : ''}`} onClick={() => handleFilterKey(filterKey)}>
      {hasImage ? (
        <img
          src={imgUrl || ''}
          alt={filterKey}
          width={imgWidth}
          height={imgHeight}
        />
      ) : (filterName || 'filter')}
    </div>
  )
}

export default FilterItem
