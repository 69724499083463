/* eslint-disable consistent-return */
/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import {
  Button, Collapse, Drawer, Dropdown, Form, Input, message, Modal, Select,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { EditFilled } from '@ant-design/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { get } from '../../../../util/localStorage'
import {
  activateSeller, deactivateSeller, editSellerApi, getDetailSellerApi,
} from '../../../../apis/partnerApis'
import { mappingErrorMessage } from '../../../../util/partnerAccount'
import UploadImageComponent from '../../../../components/common/uploadImage'
import ButtonSave from '../../../../components/simPage/buttonSave'
import { DEFAULT_IMAGE, regexPhoneNumber } from '../../../../constants/constant'
import { PERMISSION, ROLE } from '../../../../constants/permission'
import { getPermisionByRole, getPermission, isHasPermission } from '../../../../redux/reducers/permission'
import ModalResetPassword from './ModalResetPassword'

const getTextTag = {
  suspend: 'Tạm ngừng',
  active: 'Hoạt động',
  rejected: 'Từ chối',
}

function DetailSeller(props) {
  const {
    isOpen,
    setIsOpen,
    getListSeller,
    id,
    status,
    setStatus,
    isProfile,
    detail,
  } = props
  const [avatarUrl, setAvatarUrl] = useState('')
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [activeKey, setActiveKey] = useState(['1', '2', '3', '4']) // state active key of collapse
  const [isEditing, setIsEditing] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [username, setUsername] = useState('')
  const [dataDetail, setDataDetail] = useState(null)
  const [note, setNote] = useState('')
  const [isOpenModalResetPassword, setIsOpenModalResetPassword] = useState(false) // Modal thiết lập lại mật khẩu tài khoản partner

  const representative = get('MY_ROLES')?.[0] === ROLE.PARTNER_REPRESENTATIVE ? get('USER') : null

  const [editForm] = Form.useForm()

  const handleCloseDrawer = () => {
    setIsOpen(false)
    editForm.resetFields()
    setAvatarUrl(DEFAULT_IMAGE)
  }

  const handleClickCancel = () => {
    handleCloseDrawer()
  }

  const handleClickEdit = () => {
    editForm.submit()
  }

  const editSeller = async () => {
    setLoadingEdit(true)
    try {
      const data = {
        ...editForm.getFieldsValue(),
        username: `${editForm.getFieldValue('username')}`,
      }
      await editSellerApi(id, {
        ...data,
      })
      message.success('Sửa tài khoản thành công')
      getListSeller()
      handleCloseDrawer()
    } catch (error) {
      const errorMessage = mappingErrorMessage(error?.response.data.message)
      message.error(errorMessage)
    } finally {
      setLoadingEdit(false)
    }
  }

  const getSeller = async () => {
    try {
      let data
      if (isProfile) {
        data = detail
      } else {
        const rs = await getDetailSellerApi(id)
        data = rs?.data?.data
      }

      const valueForm = {
        ...data,
        creator_name: data?.creator?.full_name,
        created_at: moment(data?.created_at).format('DD/MM/YYYY'),
        username: `${data?.username || ''}`,
      }
      setUsername(
        `${data?.username || ''}`.replace(`${representative?.username}.`, ''),
      )
      setAvatarUrl(data?.avatar_url || DEFAULT_IMAGE)
      setDataDetail(valueForm)
      editForm.setFieldsValue(valueForm)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id && isOpen) {
      getSeller()
    }
  }, [id, isOpen])

  useEffect(() => {
    if (isOpen) {
      editForm.setFieldValue('avatar_url', avatarUrl)
      editForm.validateFields(['avatar_url'])
    }
  }, [avatarUrl])

  useEffect(() => {
    if (isOpen) {
      setActiveKey(['1', '2', '3', '4'])
      setIsEditing(false)
    }
  }, [isOpen])

  const handleChangeStatus = async () => {
    try {
      if (status !== 'active') {
        await activateSeller(id)
      } else {
        await deactivateSeller(id, { note })
      }
      const newDataDetail = {
        ...dataDetail,
        status: status === 'active' ? 'suspend' : 'active',
        note,
      }
      setStatus(status === 'active' ? 'suspend' : 'active')
      setDataDetail(newDataDetail)
      getListSeller()
      setNote('')
      message.success('Thay đổi trạng thái thành công')
    } catch (error) {
      message.error('Thay đổi trạng thái thất bại')
    }
    setIsOpenConfirmModal(false)
  }

  const handleCloseModalConfirm = () => {
    setIsOpenConfirmModal(false)
  }

  const userPermission = useSelector(getPermission)

  const items = [
    {
      // label: <ButtonEdit onClick={() => toggleField()} />,
      label: (
        <div
          className="btn-dropdown"
          role="button"
          tabIndex={0}
          // onClick={() => {
          //   if (
          //     data?.product_status === PRODUCT_STATUS.sold
          //     || data?.product_status === PRODUCT_STATUS.ordering
          //   ) {
          //     return
          //   }
          //   if (
          //     isHasPermission(
          //       PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,
          //       userPermission,
          //     )
          //   ) {
          //     toggleField()
          //   } else {
          //     message.error('Tính năng không khả dụng')
          //   }
          // }}
        >
          <img
            width={24}
            height={24}
            alt="edit"
            src="/images/common/reset.svg"
          />
          Thiết lập lại mật khẩu
        </div>
      ),
      key: 'reset_password',
      // disabled:
      //   data?.product_status === PRODUCT_STATUS.sold
      //   || data?.product_status === PRODUCT_STATUS.ordering,
    },
    // {
    //   // label: <ButtonDelete onClick={handleRemoveSim}>
    //   //   {data?.product_status === PRODUCT_STATUS.unavailable
    //   //     ? 'Khôi phục'
    //   //     : 'Gỡ số'}
    //   //        </ButtonDelete>,
    //   label:
    //     data?.product_status === PRODUCT_STATUS.unavailable ? (
    //       <div
    //         className="btn-dropdown text-green"
    //         role="presentation"
    //         onClick={() => {
    //           handleRemoveSim()
    //           setActionChangeStatus('available')
    //         }}
    //       >
    //         <ApiFilled style={{ fontSize: '24px', color: '#00BA34' }} />
    //         Khôi phục
    //       </div>
    //     ) : (
    //       <div
    //         className="btn-dropdown text-red"
    //         role="presentation"
    //         onClick={() => {
    //           handleRemoveSim()
    //           setActionChangeStatus('unavailable')
    //         }}
    //       >
    //         <img
    //           width={24}
    //           height={24}
    //           alt="edit"
    //           src="/images/common/recycle_red_fill.svg"
    //         />
    //         Gỡ sim
    //       </div>
    //     ),
    //   key: '1',
    // },
  ]

  const handleClickDropDown = ({ key }) => {
    if (key === 'reset_password') {
      setIsOpenModalResetPassword(true)
    }
  }

  return (
    <>
      <Drawer
        open={isOpen}
        title="Chi tiết tài khoản"
        width={800}
        onClose={handleCloseDrawer}
        className="drawer-common drawer-partner-account"
      >
        <Form layout="vertical" form={editForm} onFinish={editSeller}>
          {
            getPermisionByRole(ROLE.PARTNER_REPRESENTATIVE, userPermission) && (
              <div className="drawer-extra">
                <Dropdown
                  menu={{
                    items,
                    onClick: handleClickDropDown,
                  }}
                  trigger={['click']}
                  placement="bottomRight"
                  overlayClassName="sim-dropdown"
                >
                  <img alt="show-more" width={40} height={40} src="/images/common/show-more.svg" />
                </Dropdown>
              </div>
            )
          }
          {!isProfile && isHasPermission(
            PERMISSION.ACTIVE_DEACTIVE_EDIT_PARTNER,
            userPermission,
          ) && (
          <div className="drawer-extra">
            {isEditing ? (
              <ButtonSave onClick={handleClickEdit} />
            ) : (
              <Button
                className="detail-modal-btn"
                icon={<EditFilled />}
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                Chỉnh sửa
              </Button>
            )}
            {status === 'active' ? (
              <Button
                className="detail-modal-btn detail-modal-btn-suspend"
                onClick={() => setIsOpenConfirmModal(true)}
              >
                Vô hiệu hoá
              </Button>
            ) : status === 'suspend' ? (
              <Button
                className="detail-modal-btn detail-modal-btn-active"
                onClick={() => setIsOpenConfirmModal(true)}
              >
                Kích hoạt
              </Button>
            ) : null}

            <Dropdown
              menu={{
                items,
                onClick: handleClickDropDown,
              }}
              trigger={['click']}
              placement="bottomRight"
              overlayClassName="sim-dropdown"
            >
              <img alt="show-more" width={40} height={40} src="/images/common/show-more.svg" />
            </Dropdown>
          </div>
          )}
          {dataDetail?.deactivate_note && (
          <div className="reason">
            <strong>Lý do: </strong>
            {dataDetail?.deactivate_note}
          </div>
          )}
          <Form.Item name="avatar_url">
            <div
              className="account-avatar"
              style={{
                height: '288px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <UploadImageComponent
                withoutLogo
                imageUrl={avatarUrl}
                setImage={setAvatarUrl}
                isEditing={isEditing}
                validate
                onImageError={(e) => {
                  e.target.src = DEFAULT_IMAGE
                }}
              />
            </div>
          </Form.Item>
          <div className="account-fullname">{dataDetail?.full_name}</div>
          <div className="account-status">
            <div className="tag-wrapper">
              <div className={`tag tag-${status?.toLocaleLowerCase()}`}>
                {getTextTag[status?.toLocaleLowerCase()]}
              </div>
            </div>
          </div>
          <Collapse activeKey={activeKey}>
            <Collapse.Panel header="Thông tin tổng quan" key="1">
              <div className="form-divide">
                <div className="child-form">
                  <Form.Item
                    label={`Tên tài khoản (${
                      representative
                        ? `${representative?.username}.${username})`
                        : `${username})`
                    }`}
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập tên tài khoản',
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </div>

                <div className="child-form">
                  <Form.Item
                    label="Số điện thoại"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số điện thoại',
                      },
                      {
                        pattern: regexPhoneNumber,
                        message: 'Vui lòng nhập đúng số điện thoại!',
                      },
                    ]}
                  >
                    <Input disabled={!isEditing} />
                  </Form.Item>
                </div>
              </div>

              <div className="form-divide">
                <div className="child-form">
                  <Form.Item
                    label="Số CMND/CCCD"
                    name="identity_card"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập Số CMND/CCCD',
                      },
                      {
                        min: 9,
                        message: 'Vui lòng nhập đủ Số CMND/CCCD',
                      },
                      {
                        max: 12,
                        message: 'Vui lòng nhập đủ Số CMND/CCCD',
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Vui lòng nhập đúng định dạng Số CMND/CCCD',
                      },
                    ]}
                  >
                    <Input disabled={!isEditing} />
                  </Form.Item>
                </div>
                <div className="child-form">
                  <Form.Item
                    label="Giới tính"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn giới tính',
                      },
                    ]}
                  >
                    <Select size="large" disabled={!isEditing}>
                      <Select.Option value="male">Nam</Select.Option>
                      <Select.Option value="female">Nữ</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Collapse.Panel>

            {/* Thông tin khác */}
            <Collapse.Panel header="Thông tin khác" key="4">
              <div className="form-divide">
                <div className="child-form">
                  <Form.Item label="Người tạo tài khoản" name="creator_name">
                    <Input disabled />
                  </Form.Item>
                </div>
                <div className="child-form">
                  <Form.Item label="Ngày tạo tài khoản" name="created_at">
                    <Input disabled />
                  </Form.Item>
                </div>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Drawer>
      <Modal
        centered
        open={isOpenConfirmModal}
        onCancel={handleCloseModalConfirm}
        onOk={handleChangeStatus}
        wrapClassName={`modal-activate-sim ${
          status === 'suspend' ? 'modal-approve' : 'modal-reject'
        }`}
        okText="Xác nhận"
        cancelText="Hủy"
        style={{ width: 456 }}
      >
        <div className="modal-content">
          {status === 'suspend' ? (
            <div>
              <div className="modal-title">Bạn muốn kích hoạt tài khoản?</div>
              <div className="modal-description">
                Tài khoàn này sẽ được kích hoạt sau khi bạn xác nhận
              </div>
            </div>
          ) : (
            <div className="reject-info">
              <div className="modal-title">Bạn muốn vô hiệu hoá tài khoản?</div>
              <div className="modal-description">
                Tài khoàn này sẽ bị vô hiệu hoá sau khi bạn xác nhận
              </div>
              <TextArea
                rows={2}
                placeholder="Nhập lý do từ chối"
                style={{ marginBottom: '16px' }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          )}
        </div>
      </Modal>

      <ModalResetPassword
        isOpen={isOpenModalResetPassword}
        setIsOpen={setIsOpenModalResetPassword}
        username={`${
          representative
            ? `${representative?.username}.${username}`
            : `${username}`
        }`}
      />
    </>
  )
}

export default DetailSeller
