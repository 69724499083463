/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { message, Spin } from 'antd'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Axios from '../../services/Axios'
import {
  get, KEYS, remove, set,
} from '../../util/localStorage'
import MyProfile from '../myprofile/myProfile'
import { MY_ROLES } from '../../constants/constant'
import { isMobileDevice } from '../../util'
import { getPermission, isHasPermission } from '../../redux/reducers/permission'
import { PERMISSION } from '../../constants/permission'
import { url as navsURL } from '../../constants/navs.js'
import NotificationApis from '../../apis/notificationApis.js'
import Notifications from '../common/notifications.js'

const Header = ({ user, setIsShowSidebar }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isOpenDetailDrawer, setIsOpenDetailDrawer] = useState(false)
  const [isLogingOut, setIsLogingOut] = useState(false)
  const userPermission = useSelector(getPermission)

  const checkPermission = (path) => {
    switch (path) {
      // sim gói
      case navsURL.upload_sim_package:
        return isHasPermission(PERMISSION.UPLOAD_LIST_SIM_GOI, userPermission)
      case navsURL.active_sim_package:
        return isHasPermission(PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI, userPermission)
      case navsURL.sim_package_management:
        return isHasPermission(PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI, userPermission)
      case navsURL.approve_list_upload_sim_goi:
        return isHasPermission(PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI, userPermission)
      case navsURL.extend_sim_package:
        return isHasPermission(PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM, userPermission)
      // sim số
      case navsURL.upload_sim_number:
        return isHasPermission(PERMISSION.UPLOAD_LIST_SIM_SO, userPermission)
      case navsURL.active_sim_number:
        return isHasPermission(PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO, userPermission)
      case navsURL.sim_number_management:
        return isHasPermission(PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO, userPermission)
      case navsURL.approve_list_upload_sim_number:
        return isHasPermission(PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO, userPermission)
      case navsURL.extend_sim_number:
        return isHasPermission(PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM, userPermission)
      // sim du lịch
      case navsURL.upload_sim_travel:
        return isHasPermission(PERMISSION.UPLOAD_LIST_M2M_DU_LICH, userPermission)
      case navsURL.active_sim_travel:
        return isHasPermission(PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH, userPermission)
      case navsURL.sim_travel_management:
        return isHasPermission(PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH, userPermission)
      case navsURL.approve_list_upload_sim_travel:
        return isHasPermission(PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH, userPermission)
      // sim m2m
      case navsURL.upload_sim_m2m:
        return isHasPermission(PERMISSION.UPLOAD_LIST_M2M_DU_LICH, userPermission)
      case navsURL.active_sim_m2m:
        return isHasPermission(PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH, userPermission)
      case navsURL.sim_m2m_management:
        return isHasPermission(PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH, userPermission)
      case navsURL.approve_list_upload_sim_m2m:
        return isHasPermission(PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH, userPermission)
      case navsURL.extend_sim_m2m:
        return isHasPermission(PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM, userPermission)
      // Quan ly goi cuoc
      case navsURL.package_manage:
        return isHasPermission(PERMISSION.GET_LIST_PACKAGED_SIM, userPermission)
      case navsURL.package_manage_sim_dl:
        return isHasPermission(PERMISSION.GET_LIST_PACKAGED_SIM, userPermission)
      case navsURL.package_manage_sim_m2m:
        return isHasPermission(PERMISSION.GET_LIST_PACKAGED_SIM, userPermission)
      case navsURL.package_manage_sim_goi:
        return isHasPermission(PERMISSION.GET_LIST_PACKAGED_SIM, userPermission)
      case navsURL.package_manage_sim_so:
        return isHasPermission(PERMISSION.GET_LIST_PACKAGED_SIM, userPermission)
      // quản lý tài khoản
      case navsURL.internal_account:
        return isHasPermission(PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT, userPermission)
      // case navsURL.external_account:
      //   return isHasPermission(PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT, userPermission)
      case navsURL.doanhnghiep_account:
        return isHasPermission(PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT, userPermission)
      case navsURL.ctv_account:
        return isHasPermission(PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT, userPermission)
      case navsURL.partner_account:
        return isHasPermission(PERMISSION.VIEW_LIST_PARTNER_ACCOUNT, userPermission)
      case navsURL.customer_account:
        return isHasPermission(PERMISSION.VIEW_LIST_CONSUMER_ACCOUNT, userPermission)
      // quản lý đơn hàng
      case navsURL.order_management:
        return isHasPermission(PERMISSION.VIEW_LIST_ORDER, userPermission)
      // quản lý ticket
      case navsURL.ticket_management:
        return isHasPermission(PERMISSION.VIEW_LIST_TICKET, userPermission)
      // Website
      case `${navsURL.website_customer}menu`:
        return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
      case `${navsURL.website_customer}banner`:
        return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
      case `${navsURL.website_customer}featuredCategory`:
        return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
      case `${navsURL.website_customer}featured-product`:
        return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
      case `${navsURL.website_customer}hot-news`:
        return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
      case navsURL.website_customer:
        return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
      case navsURL.website_dl:
        return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
      case navsURL.website_m2m:
        return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
      case navsURL.website_china:
        return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
      case navsURL.website_taiwan:
        return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
      // quản lý sản phẩm
      case navsURL.product_portfolio:
        return isHasPermission(PERMISSION.VIEW_PRODUCT_PORTFOLIO, userPermission)
      case navsURL.product:
        return isHasPermission(PERMISSION.VIEW_LIST_PRODUCT, userPermission)
      case navsURL.product_inventory:
        return isHasPermission([PERMISSION.CREATE_EXPORT_REQUEST, PERMISSION.CREATE_IMPORT_REQUEST], userPermission)
      case navsURL.product_warranty_management:
        return isHasPermission([PERMISSION.CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST, PERMISSION.VIEW_WARRANTY_MANAGEMENT_REQUEST], userPermission)
      case navsURL.topup_order_management:
        return isHasPermission(PERMISSION.TOPUP_ORDERS_MANAGEMENT, userPermission)
      case navsURL.provider_order_management:
        return isHasPermission(PERMISSION.PROVIDER_ORDERS_MANAGEMENT, userPermission)
      case navsURL.sim_order_management:
        return isHasPermission(PERMISSION.PROVIDER_ORDERS_MANAGEMENT, userPermission)
      case navsURL.setting_management:
        return isHasPermission(PERMISSION.DISCOUNT_CARD_MANAGEMENT, userPermission)
      case navsURL.discount_card_management:
        return isHasPermission(PERMISSION.DISCOUNT_CARD_MANAGEMENT, userPermission)
      case navsURL.manage_bonus_point:
        return isHasPermission(PERMISSION.MANGAGE_BONUS, userPermission)
      // Quản lý bài viết
      case navsURL.manage_blog:
        return isHasPermission(PERMISSION.VIEW_LIST_BLOG, userPermission)
      case navsURL.cross_check:
        return isHasPermission(PERMISSION.CROSS_CHECK, userPermission)
      case navsURL.flash_sale:
        return isHasPermission(PERMISSION.FLASH_SALE, userPermission)
      case navsURL.statistic:
        return isHasPermission(PERMISSION.VIEW_STATISTIC, userPermission)
      default:
        return true
    }
  }

  useEffect(() => {
    if (userPermission) {
      const path = window.location.pathname
      const isPermission = checkPermission(path)
      if (!isPermission) {
        window.location.replace('/')
      }
    }
  }, [userPermission])

  const openDetailDrawer = () => {
    setIsOpenDetailDrawer(true)
    setIsOpenDropdown(false)
  }
  const handleLogout = async () => {
    if (isLogingOut) return
    try {
      const deviceToken = get(KEYS.FIREBASE_REGISTER_TOKEN)
      if (deviceToken) {
        setIsLogingOut(true)
        await NotificationApis.removeRegisterToken({
          token: deviceToken,
          device_type: 'web',
        })
        setIsLogingOut(false)
        set(KEYS.FIREBASE_REGISTER_TOKEN, '')
      }
      Axios.getInstance().setToken('')
      set(KEYS.TOKEN, '')
      remove(MY_ROLES)
      window.location = '/login'
    } catch (err) {
      setIsLogingOut(false)
      message.error('Đã có lỗi xảy ra khi đăng xuất, Vui Lòng thử lại sau!')
    }
  }

  const handleClickHamburger = () => {
    setIsShowSidebar(true)
    document.body.style.overflow = 'hidden'
    // document.body.style.height = `${window.innerHeight}px`
    document.body.style.height = '100vh'
    document.querySelector('.side-bar').style.height = `${window.innerHeight}px`
  }

  const isValidRole = () => {
    const myRole = (user?.roles || []).map((item) => item.role_tag) || []
    return (
      myRole.includes('customer_service_manager')
      || myRole.includes('customer_service_account')
      || myRole.includes('technical_manager')
      || myRole.includes('technical_account')
    )
  }

  return (
    <>
      <header>
        {isMobileDevice() ? (
          <div
            className="button-header"
            role="button"
            tabIndex={0}
            onClick={handleClickHamburger}
          >
            <img alt="menu" src="/images/common/hamburger.svg" />
            {/* <img src="/images/common/icon-header.svg" alt="icon-header" /> */}
          </div>
        ) : (
          <div className="logo">
            <Link to="/">
              <img src="/images/common/logo-v5.png" alt="logo" />
            </Link>
          </div>
        )}
        <div className="d-flex align-items-center">
          {
            isValidRole()
            && <Notifications />
          }
          <div
            className="header-avt"
            role="presentation"
            onClick={() => setIsOpenDropdown(true)}
            tabIndex={0}
            onBlur={() => setIsOpenDropdown(false)}
          >
            <img
              className="avt"
              src="/images/common/avatar.svg"
              alt="avatar"
              width={34}
              height={34}
            />
            {user ? <span className="header-avt-text">{user?.full_name || user?.email || 'User'}</span> : ''}
            <img
              className="avatar-dd"
              src="/images/common/arrow_drop_down.svg"
              alt="arrow_drop_down"
            />
            <div
              className="account-dd"
              style={{ display: isOpenDropdown ? 'block' : 'none' }}
            >
              <div
                className="account-dd-item"
                role="presentation"
                onClick={openDetailDrawer}
              >
                <img src="/images/common/person.svg" alt="user" />
                Tài khoản của tôi
              </div>
              <div className="account-dd-item">
                <img src="/images/common/settings.svg" alt="setting" />
                Cài đặt
              </div>
              <div
                className="account-dd-item account-dd-item-logout"
                style={{ opacity: isLogingOut ? 0.3 : 1 }}
                role="presentation"
                onClick={handleLogout}
              >
                {
                  isLogingOut ? <Spin style={{ marginRight: 10 }} /> : <img src="/images/common/logout.svg" alt="logout" />
                }
                Đăng xuất
              </div>
            </div>
          </div>
        </div>
        <MyProfile
          setIsOpenDetailDrawer={setIsOpenDetailDrawer}
          isOpenDetailDrawer={isOpenDetailDrawer}
        />
      </header>
      <div className="placeholder-header" />
    </>

  )
}

export default Header
