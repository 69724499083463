/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  Modal, Spin, Upload, message,
} from 'antd'
import heic2any from 'heic2any'
import imageCompression from 'browser-image-compression'
import { uploadImageApi, uploadImageWithoutLogoApi } from '../../apis/uploadApis'
import { DEFAULT_IMAGE } from '../../constants/constant'

const options = {
  maxSizeMB: 5, // Giới hạn kích thước tối đa của ảnh sau khi nén (5MB ở đây)
  maxWidthOrHeight: 800, // Giới hạn chiều rộng hoặc chiều cao tối đa sau khi nén (800px ở đây)
  useWebWorker: true, // Sử dụng Web Worker để nén ảnh (tùy chọn)
}

const ImageArrayUpload = ({
  maxLength, fileList, setFileList, isDisable,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [isLoading, setLoading] = useState(false)

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file) => {
    setPreviewImage(file.url || DEFAULT_IMAGE)
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const isHeicFile = (fileName) => {
    const heicExtensions = ['.heic', '.heif']
    const fileExtension = fileName.toLowerCase().split('.').pop()
    return heicExtensions.includes(`.${fileExtension}`)
  }

  const checkUpload = async (file) => file.type.startsWith('image/') || isHeicFile(file.name)

  const beforeUpload = (file) => {
    if (!checkUpload(file)) {
      message.error('Chỉ chấp nhận file ảnh')
      return false
    }
    return true
  }

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.url !== file.url))
  }

  const uploadButton = (
    !isLoading ? (
      !isDisable && (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Tải ảnh lên
        </div>
      </div>
      )
    ) : <Spin />
  )

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      if (!checkUpload(file)) {
        return
      }
      setLoading(true)
      const formData = new FormData()
      if (isHeicFile(file.name)) {
        file = await heic2any({ blob: file })
      }
      const compressedImage = await imageCompression(file, options)
      formData.append('file', compressedImage)
      const response = await uploadImageWithoutLogoApi(formData)
      const url = response?.data?.data
      if (url) {
        onSuccess(url)
        setFileList((prevList) => [...prevList, {
          status: 'done', url,
        }])
        message.success('Upload ảnh thành công!')
      } else {
        onError('error')
        message.error('Upload ảnh thất bại, Vui lòng thử lại!')
      }
      setLoading(false)
    } catch (error) {
      onError(error)
      setLoading(false)
      message.error('Upload ảnh thất bại, Vui lòng thử lại!')
    }
  }
  return (
    <>
      <Upload
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={handleRemove}
        disabled={isLoading || isDisable}
      >
        {fileList?.length >= maxLength ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  )
}
export default ImageArrayUpload
