export const DEFAULT_PAGINATE_SIZE = 20

export const DEFAULT_PAGINATE_SIM_LIST_SIZE = 50

export const UPLOAD_BLOCK_ITEMS_SIZE = 1000

export const PRODUCT_STATUS = {
  unavailable: 'unavailable',
  available: 'available',
  sold: 'sold',
  ordering: 'ordering',
}

export const PRODUCT_STATUS_WITH_KEY = [
  {
    key: 'unavailable',
    value: 'Không có sẵn',
  },
  {
    key: 'available',
    value: 'Có sẵn',
  },
  {
    key: 'sold',
    value: 'Đã bán',
  },
]

export const CONVERT_PRODUCT_STATUS = {
  unavailable: 'Không có sẵn',
  available: 'Có sẵn',
  ordering: 'Đang đặt hàng',
  sold: 'Đã bán',
}
