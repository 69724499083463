import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'antd/dist/reset.css'
import {
  DatePicker,
  Input,
  InputNumber,
  Select,
  Collapse,
  Tabs,
  message,
} from 'antd'
import { useEffect } from 'react'
import { CaretDownFilled, CaretRightOutlined } from '@ant-design/icons'
import { Provider } from 'react-redux'
import DefaultLayout from './views/defaultLayout'
import LoginPage from './views/LoginPage'
import './styles/index.scss'
import store from './redux/store'

Select.defaultProps = {
  suffixIcon: <CaretDownFilled />,
  size: 'large',
  style: { width: '100%' },
}

DatePicker.defaultProps = {
  size: 'large',
  style: { width: '100%' },
  format: 'DD/MM/YYYY',
}

Input.defaultProps = {
  size: 'large',
  style: { width: '100%' },
}

InputNumber.defaultProps = {
  size: 'large',
  style: { width: '100%' },
}

Collapse.defaultProps = {
  bordered: false,
  expandIcon: ({ isActive }) => (
    <CaretRightOutlined rotate={isActive ? 90 : 0} />
  ),
}

Tabs.defaultProps = {
  tabBarGutter: 0,
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" name="Login" component={LoginPage} />
          <Route path="/" name="Default Layout" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </Provider>
  )
}

export default App
