import React from 'react'
import { Modal } from 'antd'

function ModalDelete(props) {
  const { isOpen, setIsOpen, deleteAccounts } = props
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      open={isOpen}
      // open
      title="Bạn muốn xoá những tài khoản này?"
      onOk={deleteAccounts}
      onCancel={handleCloseModal}
      width={456}
      okButtonProps={{ className: 'btn-red' }}
      cancelButtonProps={{ size: 'large' }}
      okText="Xác nhận"
      cancelText="Huỷ"
      centered
    >
      <div className="">Bạn có chắc muốn xoá những tài khoản này. Sẽ không thể khôi phục lại</div>
    </Modal>
  )
}

export default ModalDelete
