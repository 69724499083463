import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import DetailAccountDrawer from '../../views/accountManagePage/internalAccount/DetailAccountDrawer'
import { getProfile } from '../../apis/authenticationApis'
import InternalAccountApis from '../../apis/internalAccountApis'
import ExternalAccountDrawer from '../../views/accountManagePage/externalAccount/ExternalAccountDrawer'
import DetailPartner from '../../views/accountManagePage/partner/adminView/DetailPartner'
import DetailSeller from '../../views/accountManagePage/partner/partnerView/DetailSeller'
import { getProviders } from '../../apis/providerApis'
import { BIG_PROVIDERS_NAME } from '../../constants/constant'

const getMyProfile = async () => {
  const res = await getProfile()
  return {
    ...res?.data?.data,
    // account_id: res?.data?.data?.id,
  }
}

function MyProfile({ isOpenDetailDrawer, setIsOpenDetailDrawer }) {
  const [departmentList, setDepartmentList] = useState([])
  const [profile, setProfile] = useState(null)
  const [type, setType] = useState('internal')
  const [providerList, setProviderList] = useState([])
  const [isDelayClose, setIsDelayClose] = useState(false)

  const getDepartmentList = async () => {
    try {
      const res = await InternalAccountApis.getDeparmentList()
      setDepartmentList(res?.data?.data)
    } catch (error) {
      // message.error('Lấy phòng ban bị lỗi')
    }
  }

  const getProviderList = async () => {
    try {
      const res = await getProviders()
      const data = res?.data?.data?.filter((item) => BIG_PROVIDERS_NAME.includes(item.name))
      setProviderList(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProviderList()
  }, [])

  const initDataProfile = async () => {
    const dataProfile = await getMyProfile()
    setProfile(dataProfile)
    setType(
      dataProfile?.role_tag === 'partner_seller'
        ? 'partner_seller'
        : dataProfile?.account_type || 'internal',
    )
  }

  useEffect(() => {
    initDataProfile()
  }, [])

  useEffect(() => {
    if (isOpenDetailDrawer) {
      setIsDelayClose(true)
    } else {
      setTimeout(() => {
        setIsDelayClose(false)
      }, 300)
    }
    if (!departmentList.length) {
      getDepartmentList()
    }
  }, [isOpenDetailDrawer])

  const getMyProfileFunction = () => profile

  return (
    <>
      {profile?.id && isDelayClose && (
        <>
          {type === 'partner_seller' ? (
            <DetailSeller
              isOpen={isOpenDetailDrawer}
              setIsOpen={setIsOpenDetailDrawer}
              detail={profile}
              id={profile?.id}
              isProfile
            />
          ) : type === 'partner' ? (
            <DetailPartner
              isOpen={isOpenDetailDrawer}
              setIsOpen={setIsOpenDetailDrawer}
              selectedRepresentative={{
                ...profile,
                representative_id: profile.id,
              }}
              isProfile
            />
          ) : type === 'internal' ? (
            <DetailAccountDrawer
              isOpenDetailDrawer={isOpenDetailDrawer}
              setIsOpenDetailDrawer={setIsOpenDetailDrawer}
              departmentList={departmentList}
              getDetailAccountFunction={getMyProfileFunction}
              isProfile
              providerList={providerList}
            />
          ) : (
            <ExternalAccountDrawer
              isOpenDrawer={isOpenDetailDrawer}
              setIsOpenDrawer={setIsOpenDetailDrawer}
              getDetailAccountFunction={getMyProfileFunction}
              detailID={profile?.id}
              isProfile
            />
          )}
        </>
      )}
    </>
  )
}

export default MyProfile
