import Axios from '../services/Axios'
import { ROLE } from '../constants/permission'
import { ROLE_ADMIN } from '../constants/constant'

const InternalAccountApis = {
  getDeparmentList() {
    return Axios.getInstance().get('/api/v1/accounts/deparments')
  },

  getRoleList(params) {
    return Axios.getInstance().get('/api/v1/accounts/internal-roles', { params })
  },

  getAllRole() {
    return Axios.getInstance().get('/api/v1/accounts/internal-roles')
  },

  createAccount(data) {
    return Axios.getInstance().post('/api/v1/accounts/internal-account', data)
  },

  getInternalAccount(params) {
    return Axios.getInstance().get('/api/v1/accounts/internal-accounts', { params })
  },

  getDetailAccount(id) {
    return Axios.getInstance().get(`/api/v1/accounts/internal-account/${id}`)
  },

  updateAccount(id, data) {
    return Axios.getInstance().put(`/api/v1/accounts/internal-account/${id}`, data)
  },

  activateAccount(id) {
    return Axios.getInstance().put(`/api/v1/accounts/internal-account/${id}/activate`)
  },

  deactivateAccount(id, data) {
    return Axios.getInstance().put(`/api/v1/accounts/internal-account/${id}/deactivate`, data)
  },

  changeStatusAccount(id, currentStatus, body = {}) {
    return Axios.getInstance().put(`/api/v1/accounts/internal-account/${id}/${currentStatus === 'active' ? 'deactivate' : 'activate'}`, body)
  },

  getSellerApi(params) {
    return Axios.getInstance().get('/api/v1/accounts/sellers', { params })
  },

  getTechnicalAccountApi(params) {
    return Axios.getInstance().get('/api/v1/accounts/internal-accounts', {
      params: {
        ...params,
        // department_id: '79569d89-cb2f-4f59-8473-cea47cf0d1e1',
        // department_id: 'a5767e1c-88a5-43ba-9236-4b67769575e5, 4a4e639b-79b4-4fa5-9935-4ede849a54d8',
        // role_tags: ['customer_service_manager', 'customer_service_account'],
        role_tags: ['technical_manager', 'technical_account', 'super_admin', 'finance_admin', 'managing_admin'],
      },
    })
  },

  getCskhAccountApi(params) {
    return Axios.getInstance().get('/api/v1/accounts/internal-accounts', {
      params: {
        ...params,
        role_tags: ['customer_service_manager', 'customer_service_account', 'super_admin', 'finance_admin', 'managing_admin'],
      },
    })
  },

  getMarketingAccountApi(params) {
    return Axios.getInstance().get('/api/v1/accounts/internal-accounts', {
      params: {
        ...params,
        role_tags: [...ROLE_ADMIN, ROLE.MARKETTING_ACCOUNT, ROLE.MARKETTING_MANAGER],
      },
    })
  },

  deleteAccount(id) {
    return Axios.getInstance().delete(`/api/v1/accounts/internal-account/${id}`)
  },
}

export default InternalAccountApis
