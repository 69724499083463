const ErrorMessage = ({ text, isShow }) => (
  <div style={{
    display: isShow ? 'flex' : 'none', flexWrap: 'nowrap', position: 'absolute', color: '#ff4d4f',
  }}
  >
    <div id="price_help" className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-yp8pcc" role="alert">
      <div className="ant-form-item-explain-error">
        {text}
      </div>

    </div>
    <div style={{ width: '0px', height: '24px' }} />
  </div>
)

export default ErrorMessage
