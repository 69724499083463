import React, { useMemo, useState } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { BONUS_POINT_ACTION, BONUS_POINT_STATUS } from '../../constants/constant'
import { formatNumber } from '../../util'

function BonusPointHistory({
  data, setData = () => {}, setQueryParams, loadingHistory, page, totalCount,
}) {
  const [activeTab, setActiveTab] = useState('point-change')

  const paginationConfig = useMemo(
    () => ({
      defaultPageSize: 10, // Set initial page size
      onChange: (pageNumber, pageSize) => {
        setQueryParams((prev) => ({
          ...prev,
          page: pageNumber,
        }))
      },
      showSizeChanger: false,
      total: totalCount,
      current: parseInt(page, 10),
    }),
    [totalCount, page],
  )

  const handleChangeTab = (tab) => {
    setData([])
    setActiveTab(tab)
    if (tab === 'point-change') {
      setQueryParams((prev) => ({
        ...prev,
        page: 1,
        limit: 10,
        transaction_types: ['edit_point', 'admin_add_point', 'admin_subtract_point', 'add_point'],
      }))
    } else {
      setQueryParams((prev) => ({
        ...prev,
        page: 1,
        limit: 10,
        transaction_types: ['withdraw_point'],
      }))
    }
  }

  const columns = [
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => (
        moment(created_at).format('DD/MM/YYYY')
      ),
    },
    {
      title: 'Hình thức',
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      render: (transaction_type) => (
        BONUS_POINT_ACTION?.[transaction_type]?.name
      ),
    },
    {
      title: 'Chi tiết',
      dataIndex: 'note',
      key: 'note',
      render: (note, record) => (note.replace('Admin', record?.updated_user)),
    },
    {
      title: 'Số điểm',
      dataIndex: 'point',
      key: 'point',
      align: 'right',
      render: (point, record) => (
        <div className={BONUS_POINT_ACTION?.[record?.transaction_type]?.text_color_class}>{`${BONUS_POINT_ACTION?.[record?.transaction_type]?.prefix}${formatNumber(point)}`}</div>
      ),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (amount, record) => (
        formatNumber(record?.point * 100)
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'transaction_status',
      key: 'transaction_status',
      align: 'center',
      render: (item) => (
        <div className="tag-wrapper">
          <div className={`tag tag-${item.toLocaleLowerCase()}`}>
            {BONUS_POINT_STATUS?.[item]}
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="point-history">
      <div className="point-history-action">
        <div className="point-history-item-wrapper">
          <button type="button" className={`point-history-action-item ${activeTab === 'point-change' ? 'active' : ''}`} onClick={() => handleChangeTab('point-change')}>Biến động điểm</button>
          <button type="button" className={`point-history-action-item ${activeTab === 'point-convert' ? 'active' : ''}`} onClick={() => handleChangeTab('point-convert')}>Đổi điểm</button>
        </div>
      </div>
      <Table
        // columns={activeTab === 'point-convert' ? columns : columns.filter((item) => (item.key !== 'transaction_status' && item.key !== 'amount'))}
        columns={activeTab === 'point-convert' ? columns : columns.filter((item) => (item.key !== 'amount'))}
        dataSource={data}
        loading={loadingHistory}
        pagination={paginationConfig}
      />
    </div>
  )
}

export default BonusPointHistory
