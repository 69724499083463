import Axios from '../services/Axios'

const BonusPointApis = {
  editRewardPoint(userID, data) {
    return Axios.getInstance().put(`/api/v1/commissions/${userID}/change-point`, data)
  },

  getHistorWallet(seller_id, params) {
    return Axios.getInstance().get(`/api/v1/commissions/${seller_id}/transactions`, { params })
  },

  getMyHistoryWallet(params) {
    return Axios.getInstance().get('/api/v1/commissions/my/transactions', { params })
  },

  approveRequest(id) {
    return Axios.getInstance().put(`/api/v1/commissions/exchange-point/${id}/approve`)
  },

  rejectRequest(id, data) {
    return Axios.getInstance().put(`/api/v1/commissions/exchange-point/${id}/cancel`, data)
  },

  exchangeRequest(data) {
    return Axios.getInstance().post('/api/v1/commissions/exchange-point', { ...data })
  },

  getExchangeRequest(params) {
    return Axios.getInstance().get('/api/v1/commissions/exchange-tickets', { params })
  },

}

export default BonusPointApis
