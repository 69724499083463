export const optionIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99967 8.33301C4.08301 8.33301 3.33301 9.08301 3.33301 9.99967C3.33301 10.9163 4.08301 11.6663 4.99967 11.6663C5.91634 11.6663 6.66634 10.9163 6.66634 9.99967C6.66634 9.08301 5.91634 8.33301 4.99967 8.33301ZM14.9997 8.33301C14.083 8.33301 13.333 9.08301 13.333 9.99967C13.333 10.9163 14.083 11.6663 14.9997 11.6663C15.9163 11.6663 16.6663 10.9163 16.6663 9.99967C16.6663 9.08301 15.9163 8.33301 14.9997 8.33301ZM9.99967 8.33301C9.08301 8.33301 8.33301 9.08301 8.33301 9.99967C8.33301 10.9163 9.08301 11.6663 9.99967 11.6663C10.9163 11.6663 11.6663 10.9163 11.6663 9.99967C11.6663 9.08301 10.9163 8.33301 9.99967 8.33301Z" fill="#969696" />
  </svg>
)

export const editIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 17.4596V20.4996C3 20.7796 3.22 20.9996 3.5 20.9996H6.54C6.67 20.9996 6.8 20.9496 6.89 20.8496L17.81 9.93957L14.06 6.18957L3.15 17.0996C3.05 17.1996 3 17.3196 3 17.4596ZM20.71 7.03957C21.1 6.64957 21.1 6.01957 20.71 5.62957L18.37 3.28957C17.98 2.89957 17.35 2.89957 16.96 3.28957L15.13 5.11957L18.88 8.86957L20.71 7.03957Z" fill="#969696" />
  </svg>
)

export const deleteIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z" fill="#E92C2C" />
  </svg>
)

export const downloadIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.825 7.5H12.5V3.33333C12.5 2.875 12.125 2.5 11.6666 2.5H8.33329C7.87496 2.5 7.49996 2.875 7.49996 3.33333V7.5H6.17496C5.43329 7.5 5.05829 8.4 5.58329 8.925L9.40829 12.75C9.73329 13.075 10.2583 13.075 10.5833 12.75L14.4083 8.925C14.9333 8.4 14.5666 7.5 13.825 7.5ZM4.16663 15.8333C4.16663 16.2917 4.54163 16.6667 4.99996 16.6667H15C15.4583 16.6667 15.8333 16.2917 15.8333 15.8333C15.8333 15.375 15.4583 15 15 15H4.99996C4.54163 15 4.16663 15.375 4.16663 15.8333Z" fill="#969696" />
  </svg>
)

export const uploadIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33366 13.3331H11.667C12.1253 13.3331 12.5003 12.9581 12.5003 12.4998V8.33311H13.8253C14.567 8.33311 14.942 7.43311 14.417 6.90811L10.592 3.08311C10.267 2.75811 9.74199 2.75811 9.41699 3.08311L5.59199 6.90811C5.06699 7.43311 5.43366 8.33311 6.17533 8.33311H7.50033V12.4998C7.50033 12.9581 7.87533 13.3331 8.33366 13.3331ZM5.00033 14.9998H15.0003C15.4587 14.9998 15.8337 15.3748 15.8337 15.8331C15.8337 16.2914 15.4587 16.6664 15.0003 16.6664H5.00033C4.54199 16.6664 4.16699 16.2914 4.16699 15.8331C4.16699 15.3748 4.54199 14.9998 5.00033 14.9998Z" fill="#969696" />
  </svg>
)

export const arrowForwardIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 12.9997H16.17L11.29 17.8797C10.9 18.2697 10.9 18.9097 11.29 19.2997C11.68 19.6897 12.31 19.6897 12.7 19.2997L19.29 12.7097C19.68 12.3197 19.68 11.6897 19.29 11.2997L12.71 4.69973C12.32 4.30973 11.69 4.30973 11.3 4.69973C10.91 5.08973 10.91 5.71973 11.3 6.10973L16.17 10.9997H5C4.45 10.9997 4 11.4497 4 11.9997C4 12.5497 4.45 12.9997 5 12.9997Z" fill="#1C1C1C" />
  </svg>
)

export const correctIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0003 1.66602C5.40033 1.66602 1.66699 5.39935 1.66699 9.99935C1.66699 14.5993 5.40033 18.3327 10.0003 18.3327C14.6003 18.3327 18.3337 14.5993 18.3337 9.99935C18.3337 5.39935 14.6003 1.66602 10.0003 1.66602ZM7.74199 13.5743L4.75033 10.5827C4.42533 10.2577 4.42533 9.73268 4.75033 9.40768C5.07533 9.08268 5.60033 9.08268 5.92533 9.40768L8.33366 11.8077L14.067 6.07435C14.392 5.74935 14.917 5.74935 15.242 6.07435C15.567 6.39935 15.567 6.92435 15.242 7.24935L8.91699 13.5743C8.60033 13.8993 8.06699 13.8993 7.74199 13.5743Z" fill="#00BA34" />
  </svg>
)

export const incorrectIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0003 1.66602C5.39199 1.66602 1.66699 5.39102 1.66699 9.99935C1.66699 14.6077 5.39199 18.3327 10.0003 18.3327C14.6087 18.3327 18.3337 14.6077 18.3337 9.99935C18.3337 5.39102 14.6087 1.66602 10.0003 1.66602ZM13.5837 13.5827C13.2587 13.9077 12.7337 13.9077 12.4087 13.5827L10.0003 11.1743L7.59199 13.5827C7.26699 13.9077 6.74199 13.9077 6.41699 13.5827C6.09199 13.2577 6.09199 12.7327 6.41699 12.4077L8.82533 9.99935L6.41699 7.59102C6.09199 7.26602 6.09199 6.74102 6.41699 6.41602C6.74199 6.09102 7.26699 6.09102 7.59199 6.41602L10.0003 8.82435L12.4087 6.41602C12.7337 6.09102 13.2587 6.09102 13.5837 6.41602C13.9087 6.74102 13.9087 7.26602 13.5837 7.59102L11.1753 9.99935L13.5837 12.4077C13.9003 12.7243 13.9003 13.2577 13.5837 13.5827Z" fill="#E92C2C" />
  </svg>
)

export const imagePreviewIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333ZM7.41667 11.65L9.16667 13.7583L11.75 10.4333C11.9167 10.2167 12.25 10.2167 12.4167 10.4417L15.3417 14.3417C15.55 14.6167 15.35 15.0083 15.0083 15.0083H5.01667C4.66667 15.0083 4.475 14.6083 4.69167 14.3333L6.76667 11.6667C6.925 11.45 7.24167 11.4417 7.41667 11.65Z" fill="#969696" />
  </svg>
)

export const exportIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6663 1.66675H4.99967C4.08301 1.66675 3.34134 2.41675 3.34134 3.33341L3.33301 16.6667C3.33301 17.5834 4.07467 18.3334 4.99134 18.3334H14.9997C15.9163 18.3334 16.6663 17.5834 16.6663 16.6667V6.66675L11.6663 1.66675ZM14.9997 16.6667H4.99967V3.33341H10.833V7.50008H14.9997V16.6667ZM6.66634 12.5084L7.84134 13.6834L9.16634 12.3667V15.8334H10.833V12.3667L12.158 13.6917L13.333 12.5084L10.008 9.16675L6.66634 12.5084Z" fill="#969696" />
  </svg>
)

export const fileIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.49993 13.4996L4.58327 10.5829C4.25827 10.2579 3.7416 10.2579 3.4166 10.5829C3.0916 10.9079 3.0916 11.4246 3.4166 11.7496L6.90827 15.2413C7.23327 15.5663 7.75827 15.5663 8.08327 15.2413L16.9166 6.41628C17.2416 6.09128 17.2416 5.57461 16.9166 5.24961C16.5916 4.92461 16.0749 4.92461 15.7499 5.24961L7.49993 13.4996Z" fill="#0085FF" />
  </svg>
)

export const deleteFileIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 15.8333C4.99984 16.75 5.74984 17.5 6.6665 17.5H13.3332C14.2498 17.5 14.9998 16.75 14.9998 15.8333V7.5C14.9998 6.58333 14.2498 5.83333 13.3332 5.83333H6.6665C5.74984 5.83333 4.99984 6.58333 4.99984 7.5V15.8333ZM14.9998 3.33333H12.9165L12.3248 2.74167C12.1748 2.59167 11.9582 2.5 11.7415 2.5H8.25817C8.0415 2.5 7.82484 2.59167 7.67484 2.74167L7.08317 3.33333H4.99984C4.5415 3.33333 4.1665 3.70833 4.1665 4.16667C4.1665 4.625 4.5415 5 4.99984 5H14.9998C15.4582 5 15.8332 4.625 15.8332 4.16667C15.8332 3.70833 15.4582 3.33333 14.9998 3.33333Z" fill="#969696" />
  </svg>
)

export const errorIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.99996 1.66699C5.39996 1.66699 1.66663 5.40033 1.66663 10.0003C1.66663 14.6003 5.39996 18.3337 9.99996 18.3337C14.6 18.3337 18.3333 14.6003 18.3333 10.0003C18.3333 5.40033 14.6 1.66699 9.99996 1.66699ZM9.99996 14.167C9.54163 14.167 9.16663 13.792 9.16663 13.3337V10.0003C9.16663 9.54199 9.54163 9.16699 9.99996 9.16699C10.4583 9.16699 10.8333 9.54199 10.8333 10.0003V13.3337C10.8333 13.792 10.4583 14.167 9.99996 14.167ZM10.8333 7.50033H9.16663V5.83366H10.8333V7.50033Z" fill="#E92C2C" />
  </svg>
)
