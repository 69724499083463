import Axios from '../services/Axios'

export function signInApi(username, password) {
  return Axios.getInstance().post('/api/v1/login', {
    username,
    password,
  })
}

export function getProfile() {
  return Axios.getInstance().get('/api/v1/my/profile')
}

export function resetPassword(data) {
  return Axios.getInstance().put('api/v1/accounts/reset-password', data)
}

export function resetPasswordPartnerSeller(data) {
  return Axios.getInstance().put('api/v1/partners/reset-password/sellers', data)
}
