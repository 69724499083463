import Loadable from 'react-loadable'
import { url } from './navs'
import KHDN from '../views/accountManagePage/externalAccount/KHDN'

function Loading() {
  return <div>Loading...</div>
}

const HomePage = Loadable({
  loader: () => import('../views/homePage'),
  loading: Loading,
})

const OrderPage = Loadable({
  loader: () => import('../views/orderPage/index'),
  loading: Loading,
})

const ListSimPage = Loadable({
  loader: () => import('../views/simPage/listSIM'),
  loading: Loading,
})

const TopupOrderManage = Loadable({
  loader: () => import('../views/topupOrderManagePage/index'),
  loading: Loading,
})

const ProviderOrderManage = Loadable({
  loader: () => import('../views/providerOrderManagePage/index'),
  loading: Loading,
})

const PackageOrderManage = Loadable({
  loader: () => import('../views/packageOrderManagePage/packageOrderManage'),
  loading: Loading,
})

const UploadSimPage = Loadable({
  loader: () => import('../views/simPage/uploadSIM'),
  loading: Loading,
})

const ListSimM2MPage = Loadable({
  loader: () => import('../views/simM2MPage/listSIM'),
  loading: Loading,
})

const UploadSimM2MPage = Loadable({
  loader: () => import('../views/simM2MPage/uploadSIM'),
  loading: Loading,
})

const ListSimDLage = Loadable({
  loader: () => import('../views/simDLPage/listSIM'),
  loading: Loading,
})

const UploadSimDLPage = Loadable({
  loader: () => import('../views/simDLPage/uploadSIM'),
  loading: Loading,
})

const ApproveSimPage = Loadable({
  loader: () => import('../views/simPage/approveSIM'),
  loading: Loading,
})
const ApprovePackagedSimPage = Loadable({
  loader: () => import('../views/simPackagePage/approveSIM'),
  loading: Loading,
})

const ApproveSimM2MPage = Loadable({
  loader: () => import('../views/simM2MPage/approveSIM'),
  loading: Loading,
})

const ApproveSimDLPage = Loadable({
  loader: () => import('../views/simDLPage/approveSIM'),
  loading: Loading,
})

const LandingPageManager = Loadable({
  loader: () => import('../views/websiteManager/landingPageManager'),
  loading: Loading,
})

const LandingPageM2MManager = Loadable({
  loader: () => import('../views/websiteManager/landingPageM2MManager'),
  loading: Loading,
})

const LandingPageChina = Loadable({
  loader: () => import('../views/websiteManager/landingPageChina'),
  loading: Loading,
})

const LandingPageTaiwan = Loadable({
  loader: () => import('../views/websiteManager/landingPageTaiwan'),
  loading: Loading,
})

const ActivateSimDLManagePage = Loadable({
  loader: () => import('../views/activateSimDLManagePage'),
  loading: Loading,
})

const ActivateSimM2MManagePage = Loadable({
  loader: () => import('../views/activateSimM2MManagePage'),
  loading: Loading,
})

const TicketManagePage = Loadable({
  loader: () => import('../views/ticketManagePage'),
  loading: Loading,
})

const CustomerManagerPage = Loadable({
  loader: () => import('../views/customerManagePage'),
  loading: Loading,
})

const ActicePackagedSimManagePage = Loadable({
  loader: () => import('../views/activatePackagedSimManagePage'),
  loading: Loading,
})

const ActiceSimManagePage = Loadable({
  loader: () => import('../views/activateSimManagePage'),
  loading: Loading,
})

const InternalAccountManagePage = Loadable({
  loader: () => import('../views/accountManagePage/internalAccount/InternalAccount'),
  loading: Loading,
})

const CTVAccountManagePage = Loadable({
  loader: () => import('../views/accountManagePage/externalAccount/ExternalAccount'),
  loading: Loading,
})

const KHDNAccountManagePage = Loadable({
  loader: () => import('../views/accountManagePage/externalAccount/KHDN'),
  loading: Loading,
})
const PartnerAccountManagePage = Loadable({
  loader: () => import('../views/accountManagePage/partner'),
  loading: Loading,
})

const ConsumerAccountManagePage = Loadable({
  loader: () => import('../views/accountManagePage/consumerAccount'),
  loading: Loading,
})

// const PackageManagePage = Loadable({
//   loader: () => import('../views/packageManagePage/packageManage'),
//   loading: Loading,
// })

const PackageManagePageSimDL = Loadable({
  loader: () => import('../views/packageManagePage/packageManageSimDL'),
  loading: Loading,
})

const PackageManagePageSimM2M = Loadable({
  loader: () => import('../views/packageManagePage/packageManageSimM2M'),
  loading: Loading,
})

const PackageManagePageSimGoi = Loadable({
  loader: () => import('../views/packageManagePage/packageManageSimGoi'),
  loading: Loading,
})

const PackageManagePageSimSo = Loadable({
  loader: () => import('../views/packageManagePage/packageManageSimSo'),
  loading: Loading,
})

const ProductCategory = Loadable({
  loader: () => import('../views/productManagePage/productCategory/productCategory'),
  loading: Loading,
})
const ProductList = Loadable({
  loader: () => import('../views/productManagePage/productList/ProductList'),
  loading: Loading,
})
const InventoryManagePage = Loadable({
  loader: () => import('../views/productManagePage/inventoryManage/inventoryManage'),
  loading: Loading,
})
const GuaranteeManagePage = Loadable({
  loader: () => import('../views/productManagePage/guaranteeManage/guaranteeManage'),
  loading: Loading,
})

// Sim package
const SimPackageList = Loadable({
  loader: () => import('../views/simPackagePage/SimPackageList'),
  loading: Loading,
})

const UploadSimPackagePage = Loadable({
  loader: () => import('../views/simPackagePage/uploadSIM'),
  loading: Loading,
})

// Gia hạn sim số
const ExtendPackageSimPage = Loadable({
  loader: () => import('../views/extendSimPage/ExtendPackageSimPage'),
  loading: Loading,
})

// Gia hạn sim gói
const ExtendSimPackage = Loadable({
  loader: () => import('../views/extendSimPackage/ExtendSimPackage'),
  loading: Loading,
})

// Gia hạn sim M2M
const ExtendSimM2M = Loadable({
  loader: () => import('../views/extendSimM2M/ExtendSimM2M'),
  loading: Loading,
})

// Thiet lap nap the
const DiscountCard = Loadable({
  loader: () => import('../views/discountCardManagePage/index'),
  loading: Loading,
})

// Quản lý điểm thưởng
const ManageBonusPoint = Loadable({
  loader: () => import('../views/manageBonusPoint/manageBonusPoint'),
  loading: Loading,
})

// Thống kê kinh doanh
const Statistic = Loadable({
  loader: () => import('../views/statisticPage'),
  loading: Loading,
})

// Quản lý bài viết
const ManageBlog = Loadable({
  loader: () => import('../views/manageBlogPage/index'),
  loading: Loading,
})

// Quản lý bài viết
const CrossCheckManagement = Loadable({
  loader: () => import('../views/crossCheckManagement/crossCheckManagement'),
  loading: Loading,
})

// Quản lý flash sale
const FlashSalePage = Loadable({
  loader: () => import('../views/flashSalePage'),
  loading: Loading,
})

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home Page',
    component: HomePage,
  },
  {
    path: '/home',
    exact: true,
    name: 'Home Page',
    component: HomePage,
  },
  {
    path: '/quan-ly-don-hang/quan-ly-sim-so',
    name: 'Order Page',
    exact: true,
    component: OrderPage,
  },
  {
    path: '/quan-ly-don-hang/nap-the',
    name: 'Topup Order Page',
    exact: true,
    component: TopupOrderManage,
  },
  {
    path: '/quan-ly-don-hang/nha-mang',
    name: 'Topup Order Page',
    exact: true,
    component: ProviderOrderManage,
  },
  {
    path: '/quan-ly-don-hang/goi-cuoc',
    name: 'Package Order Page',
    exact: true,
    component: PackageOrderManage,
  },
  {
    path: '/sim-so/kho-sim',
    exact: true,
    name: 'List Sim Page',
    component: ListSimPage,
  },
  {
    path: '/sim-so/upload',
    exact: true,
    name: 'Upload Sim Page',
    component: UploadSimPage,
  },
  {
    path: '/sim-m2m/kho-sim',
    exact: true,
    name: 'List Sim Page',
    component: ListSimM2MPage,
  },
  {
    path: '/sim-m2m/upload',
    exact: true,
    name: 'Upload Sim Page',
    component: UploadSimM2MPage,
  },
  {
    path: '/sim-du-lich/kho-sim',
    exact: true,
    name: 'List Sim Page',
    component: ListSimDLage,
  },
  {
    path: '/sim-du-lich/upload',
    exact: true,
    name: 'Upload Sim Page',
    component: UploadSimDLPage,
  },
  {
    path: '/sim-so/phe-duyet',
    exact: true,
    name: 'Upload Sim Page',
    component: ApproveSimPage,
  },
  {
    path: '/sim-goi/phe-duyet',
    exact: true,
    name: 'Upload Sim Page',
    component: ApprovePackagedSimPage,
  },
  {
    path: '/sim-m2m/phe-duyet',
    exact: true,
    name: 'Upload Sim Page',
    component: ApproveSimM2MPage,
  },
  {
    path: '/sim-du-lich/phe-duyet',
    exact: true,
    name: 'Upload Sim Page',
    component: ApproveSimDLPage,
  },
  {
    path: '/quan-ly-website/dl',
    exact: true,
    name: 'Upload Sim Page',
    component: LandingPageManager,
  },
  {
    path: '/quan-ly-website/m2m',
    exact: true,
    name: 'Upload Sim Page',
    component: LandingPageM2MManager,
  },
  {
    path: '/quan-ly-website/china',
    exact: true,
    name: 'Landing Page China Manage Page',
    component: LandingPageChina,
  },
  {
    path: '/quan-ly-website/taiwan',
    exact: true,
    name: 'Landing Page Taiwan Manage Page',
    component: LandingPageTaiwan,
  },
  {
    path: '/sim-du-lich/kich-hoat-sim',
    exact: true,
    name: 'Activate Sim Manage Page',
    component: ActivateSimDLManagePage,
  },
  {
    path: '/sim-m2m/kich-hoat-sim',
    exact: true,
    name: 'Activate Sim M2M Manage Page',
    component: ActivateSimM2MManagePage,
  },
  {
    path: '/quan-ly-ticket',
    exact: true,
    name: 'Ticket Management',
    component: TicketManagePage,
  },
  {
    path: '/quan-ly-website/trang-khach-hang',
    name: 'Activate Sim Manage Page',
    component: CustomerManagerPage,
  },
  {
    path: '/quan-ly-tai-khoan/tai-khoan-noi-bo',
    name: 'Internal Account Manage Page',
    component: InternalAccountManagePage,
  },
  {
    path: '/quan-ly-tai-khoan/tai-khoan-doanh-nghiep',
    name: 'External Account Manage Page',
    component: KHDNAccountManagePage,
  },
  {
    path: '/quan-ly-tai-khoan/tai-khoan-ctv',
    name: 'External Account Manage Page',
    component: CTVAccountManagePage,
  },
  {
    path: '/quan-ly-tai-khoan/tai-khoan-doi-tac',
    name: 'Partner Account Manage Page',
    component: PartnerAccountManagePage,
  },
  {
    path: '/quan-ly-tai-khoan/tai-khoan-khach-hang',
    name: 'Consumer Account Manage Page',
    component: ConsumerAccountManagePage,
  },
  // {
  //   path: '/quan-ly-goi-cuoc',
  //   name: 'Package Management Page',
  //   component: PackageManagePage,
  // },
  {
    path: '/quan-ly-goi-cuoc/gc-sim-dl',
    name: 'Package Management Page',
    component: PackageManagePageSimDL,
  },
  {
    path: '/quan-ly-goi-cuoc/gc-sim-m2m',
    name: 'Package Management Page',
    component: PackageManagePageSimM2M,
  },
  {
    path: '/quan-ly-goi-cuoc/gc-sim-goi',
    name: 'Package Management Page',
    component: PackageManagePageSimGoi,
  },
  {
    path: '/quan-ly-goi-cuoc/gc-sim-so',
    name: 'Package Management Page',
    component: PackageManagePageSimSo,
  },

  // Sim package
  {
    path: '/sim-goi/kho-sim',
    name: 'Sim Package List Page',
    component: SimPackageList,
  },
  {
    path: '/quan-ly-san-pham/danh-muc',
    name: 'Product Categoty Page',
    component: ProductCategory,
  },
  {
    path: '/quan-ly-san-pham/san-pham/:id',
    name: 'Product List Page',
    component: ProductList,
  },
  {
    path: '/quan-ly-san-pham/san-pham',
    name: 'Product List Page',
    component: ProductList,
  },
  {
    path: '/quan-ly-san-pham/quan-ly-kho',
    name: 'Inventory Management Page',
    component: InventoryManagePage,
  },
  {
    path: '/quan-ly-san-pham/kho-bao-hanh',
    name: 'Guarantee Management Page',
    component: GuaranteeManagePage,
  },
  {
    path: '/sim-goi/upload',
    exact: true,
    name: 'Upload Sim Package Page',
    component: UploadSimPackagePage,
  },
  {
    path: '/sim-goi/kich-hoat-sim',
    name: 'Activate Sim Manage Page',
    component: ActicePackagedSimManagePage,
  },
  {
    path: '/sim-so/kich-hoat-sim',
    name: 'Activate Sim Manage Page',
    component: ActiceSimManagePage,
  },
  {
    path: '/sim-so/gia-han-sim',
    name: 'Extend Sim Page',
    component: ExtendPackageSimPage,
  },

  {
    path: '/sim-goi/gia-han-sim',
    name: 'Extend Sim Package',
    component: ExtendSimPackage,
  },
  {
    path: '/sim-m2m/gia-han-sim',
    name: 'Extend Sim M2M',
    component: ExtendSimM2M,
  },
  {
    path: '/thiet-lap-chung/nap-the',
    name: 'Discount-card',
    component: DiscountCard,
  },
  {
    path: '/quan-ly-diem-thuong',
    name: 'Manage Bonus Point',
    component: ManageBonusPoint,
  },
  {
    path: '/thong-ke-kinh-doanh',
    name: 'Statistic',
    component: Statistic,
  },
  {
    path: '/quan-ly-bai-viet',
    name: 'Manage Blog',
    component: ManageBlog,
  },
  {
    path: '/doi-soat',
    name: 'Cross Check Management',
    component: CrossCheckManagement,
  },
  {
    path: url.flash_sale,
    name: 'Flash Sale',
    component: FlashSalePage,
  },
]

export default routes
